// Generated by Framer (2372734)

import { addFonts, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {IONvtlO6Y: {hover: true}};

const cycleOrder = ["IONvtlO6Y"];

const variantClassNames = {IONvtlO6Y: "framer-v-11zbn7b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IONvtlO6Y", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qLVsB", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "jQl8KobkC"}} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-11zbn7b", className)} framer-p0jlmt`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IONvtlO6Y"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11, boxShadow: "0px 0.48174984141951427px 1.252549587690737px -1.25px rgba(0, 0, 0, 0.28796), 0px 1.8308266425947657px 4.760149270746391px -2.5px rgba(0, 0, 0, 0.25423), 0px 8px 20.8px -3.75px rgba(0, 0, 0, 0.1)", ...style}} variants={{"IONvtlO6Y-hover": {backgroundColor: "var(--token-8b7699fe-a6ed-49e3-97cd-679d496bde7f, rgb(0, 255, 119))"}}} {...addPropertyOverrides({"IONvtlO6Y-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-font-weight": "700", "--framer-letter-spacing": "-0.4px", "--framer-line-height": "1.75em", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Sag Hallo!</motion.p></React.Fragment>} className={"framer-y2zuvm"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"nVmIJp3FA"} style={{"--extracted-r6o4lv": " rgb(35, 31, 32)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qLVsB [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qLVsB .framer-p0jlmt { display: block; }", ".framer-qLVsB .framer-11zbn7b { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 7px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 14px 8px 12px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-qLVsB .framer-y2zuvm { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-qLVsB .framer-v-11zbn7b .framer-11zbn7b { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qLVsB .framer-11zbn7b { gap: 0px; } .framer-qLVsB .framer-11zbn7b > * { margin: 0px; margin-left: calc(7px / 2); margin-right: calc(7px / 2); } .framer-qLVsB .framer-11zbn7b > :first-child { margin-left: 0px; } .framer-qLVsB .framer-11zbn7b > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 86
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"pli6S4piE":{"layout":["auto","auto"]}}}
 */
const FramerRV3ZOAxYZ: React.ComponentType<Props> = withCSS(Component, css, "framer-qLVsB") as typeof Component;
export default FramerRV3ZOAxYZ;

FramerRV3ZOAxYZ.displayName = "Sag Hallo! Button";

FramerRV3ZOAxYZ.defaultProps = {height: 39, width: 86};

addFonts(FramerRV3ZOAxYZ, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/RV3ZOAxYZ:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])